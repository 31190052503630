<template>
    <b-form class="section-unidade">
        <b-row class="pt-1 px-2" :key="indice">
            <b-col lg="4" md="4" sm="4">
                <DepartamentoRegional :isRequired="false" @updated="form.departamentoRegional = $event"
                    :clearable="true" :value="0"/>
            </b-col>
            <b-col lg="4" md="4" sm="4">
                <NomeUnidadeOperacional :isRequired="false" @updated="form.nomeUnidadeOperacional = $event" />
            </b-col>
            <b-col lg="4" md="4" sm="4">
                <Tipo :isRequired="false" @updated="form.tipo = $event" :clearable="true" :value="form.tipo" :listagem="listagem"/>
            </b-col>
        </b-row>
        <b-row class="justify-content-center">
            <b-button @click="filtrarUnidade" variant="primary-button">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Pesquisar</span>
            </b-button>

            <b-button type="reset" class="ml-2 cor_botao" @click="clearSearch" variant="outline-primary">
                <feather-icon icon="XIcon" class="mr-50" />
                <span class="align-middle">Limpar</span>
            </b-button>
        </b-row>

        <hr>
    </b-form>
</template>

<script>
import { BRow, BCol, BForm, BButton } from 'bootstrap-vue';
import Tipo from './Tipo.vue';
import DepartamentoRegional from './DepartamentoRegional.vue';
import NomeUnidadeOperacional from './NomeUnidadeOperacional.vue';
export default {
    components: {
        Tipo,
        DepartamentoRegional,
        NomeUnidadeOperacional,
        BRow,
        BCol,
        BButton,
        BForm,
    },
    props: {
        listagem: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            indice:0,
            form: {
                nomeUnidadeOperacional: "",
                tipo: "",
                departamentoRegional: null
            }
        }
    },
    methods: {
        filtrarUnidade() {
            this.$emit('filtrarUnidade', this.form)
        },
        clearSearch() {
            this.form.departamentoRegional = null;
            this.form.nomeUnidadeOperacional = "";
            this.form.tipo = "";
            this.indice++;
            this.filtrarUnidade();
        }
    }
}
</script>