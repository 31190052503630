<template>
    <section class="list-unidades">
        <ConfirmModal :modalShow="modal.showModal" :typeModal="modal.typeModal" itemIdentifier="unidade" :infoText="{
            success: 'Essa ação dará permissão de uso à unidade.',
            warning: 'A unidade não terá mais permissão de uso.',
        }" @confirmed="changeStateUnidade" @canceled="cancelarAtivaDesativaUnidade" />

        <CustomAlert variant="danger" :message="alert.errorMessage" :show="alert.show" />

        <b-table id="tableUnidade" :fields="table.fields" :items="table.items" responsive :stickyColumn="true"
            no-local-sorting :no-sort-reset="true" :busy.sync="filtering" show-empty @sort-changed="ordemColumn">

            <template #empty>
                <span v-if="filtering">Processando.</span>
                <span v-else>Nenhum registro encontrado.</span>
            </template>

            <template #cell(descricao_departamento)="row">
                <span class="font-weight-bold comprimento_maximo_celulas">
                    {{ row.item.descricao_departamento }}
                </span>
            </template>

            <template #cell(descricao_unidade)="row">
                <span class="font-weight-bold comprimento_maximo_celulas">
                    {{ row.item.descricao_unidade }}
                </span>
            </template>
            <template #cell(id_cidade)="row">
                <span class="font-weight-bold comprimento_maximo_celulas">
                    {{ row.item.descricao_cidade }}
                </span>
            </template>
            <template #cell(ativo)="row">
                <TableSwitch v-if="podeAlterarStatus(row.item)" :checked="row.item.ativo"
                    :description="row.item.descricao_unidade" :itemId="row.item.id_unidade"
                    @stateModified="openModalConfirm" />

                <TableSwitch v-else :checked="row.item.ativo" disabled :description="row.item.descricao_unidade"
                    :itemId="row.item.id_unidade" />

            </template>
            <template #cell(actions)="row">
                <feather-icon v-if="podeVisualizar" @click.prevent="redirect('unidade-view', row.item)" id="view-unidade" role="button"
                    class="text-custom-blue mr-1" icon="EyeIcon" size="18" v-b-tooltip.hover.html
                    title="<b>Detalhes da Unidade</b>" />
                
                <feather-icon v-if="podeEditar(row.item)" @click.prevent="redirect('unidade-edit', row.item)" id="edit-unidade" role="button"
                    class="text-custom-blue" icon="EditIcon" size="18" v-b-tooltip.hover.html
                    title="<b>Editar Unidade</b>" />

                <feather-icon v-else id="edit-unidade" role="button" disabled
                    class="disabled" icon="EditIcon" size="18" v-b-tooltip.hover.html
                    title="<b>Editar Unidade</b>" />
            </template>

        </b-table>
        <div class="pb-2 px-1">
            <b-col v-if="temUnidade" sm="12" class="mt-2">
                <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage" />
            </b-col>
        </div>
    </section>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BTable } from 'bootstrap-vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import { actions, subjects } from "@/libs/acl/rules";
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import CustomAlert from "@/views/components/custom/timed-alert/CustomAlert.vue";
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import {tipoUnidade} from "@/enum/tipoUnidade";

export default {
    props: {
        parametros: {
            type: Object,
            required: true,
        }
    },
    components: {
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BTable,
        TableSwitch,
        ConfirmModal,
        CustomAlert,
        CustomPagination
    },
    data() {
        return {
            filtering: false,
            table: {
                fields: [
                    { key: 'descricao_departamento', sortable: false, label: 'Departamento Regional', visible: true },
                    { key: 'descricao_unidade', sortable: true, label: 'Unidade Operacional', visible: true },
                    { key: 'id_cidade', sortable: false, label: 'Cidade', visible: true },
                    { key: 'tipo', sortable: false, label: 'Tipo', visible: true },
                    { key: 'ativo', sortable: true, label: 'status', visible: true, class: 'comprimento_status' },
                    { key: 'actions', label: 'ações', sortable: false, class: 'mw-150 text-center col-1', visible: true }
                ],
                items: [],
            },
            modal: {
                showModal: false,
                typeModal: 'success',
            },
            alert: {
                errorMessage: '',
                show: false
            },
            paginationData: null,
        }
    },
    beforeMount() {
        this.loadDataUnidade()
    },
    methods: {
        loadDataUnidade() {
            this.filtering = true;
            const params = this.parametros
            this.table.items = [];
            this.$http.get(this.$api.unidade(), { params: params }).then(({ data }) => {
                this.table.items = data.data;
                this.loadingData = false;
                this.filtering = false;

                this.paginationData = {
                    currentPage: data.current_page,
                    totalLines: data.total,
                    fromLine: data.from,
                    toLine: data.to,
                    defaultSize: params.tamanho
                };
            })

            this.loadingData = false;
        },
        openModalConfirm(value) {
            this.alert.show = false;
            this.modal.typeModal = 'warning';

            if (value.state) {
                this.modal.typeModal = 'success';
            }

            this.$helpers.changeStateActiveTableRow(this.table.items, value, 'id_unidade');

            this.itemDesativar = value;
            this.modal.showModal = true;
        },
        changeStateUnidade() {
            this.$http.patch(this.$api.unidadeParameter(this.itemDesativar.itemId)).then(() => {
                this.modal.showModal = false;
                this.alert.show = false;
            }).catch(({ response: { data } }) => {
                this.modal.showModal = false;

                this.$helpers.backStateActiveTableRow(this.table.items, this.itemDesativar, 'id_unidade');

                this.alert.errorMessage = data.errors;
                this.alert.show = !!data.errors
            });
        },
        cancelarAtivaDesativaUnidade() {
            this.$helpers.backStateActiveTableRow(this.table.items, this.itemDesativar, 'id_unidade');
            this.modal.showModal = false;
        },
        redirect(routeName, { id_unidade, descricao_unidade }) {
            this.$router.push(
                {
                    name: routeName,
                    params: {
                        id_unidade,
                        acronym: descricao_unidade
                    }
                }
            );
        },
        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.filter = !this.filter;
            this.$emit('updatePagination', this.paginationData);
        },
        ordemColumn({ sortBy, sortDesc }) {
            const dados = {
                columnOrder: sortBy,
                order: sortDesc ? 'desc' : 'asc',
            }
            this.$emit('sortTable', dados);
        },
        podeAlterarStatus(unidade) {
            return unidade.tipo == tipoUnidade.PROPRIA
                ? this.$can(actions.ATUALIZAR_STATUS, subjects.UNIDADE_PROPRIA)
                : this.$can(actions.ATUALIZAR_STATUS, subjects.UNIDADE_TERCEIRA);
        },
        podeEditar(unidade) {
            return unidade.tipo == tipoUnidade.PROPRIA
                ? this.$can(actions.ATUALIZAR, subjects.UNIDADE_PROPRIA)
                : this.$can(actions.ATUALIZAR, subjects.UNIDADE_TERCEIRA);
        },
    },
    computed: {
        podeVisualizar() {
            return this.$can(actions.VISUALIZAR, subjects.UNIDADE);
        },        
        temUnidade() {
            return this.table.items.length > 0;
        }
    },

}
</script>


<style>
.comprimento_maximo_celulas {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
}

.comprimento_status {
    width: 100px;
}
</style>