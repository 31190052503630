<template>
    <div class="content-wrapper">
        <PageHeader screenName="Ver Unidades" :linkItems="linkItems" />

        <main class="card">
            <!-- botão para adicionar nova unidade -->
            <div class="pt-2 px-2" v-if="podeCadastrarUnidade">
                <b-button @click="redirectToRegisterUnidade()" variant="primary-button">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Adicionar nova unidade</span>
                </b-button>
            </div>

            <!-- botão filtros -->
            <ListagemFiltros @filtrarUnidade="(param) => handleFormFiltros('formData', param)" :listagem="true"/>

            <!-- qtd mostrar -->
            <TableFilter :filter="filter" @filterTable="(param) => handleFormFiltros('paginationData', param)"
                :showInputFilter="false" />

            <!-- tabela -->
            <ListagemTabela :parametros="getParams" :key="indiceListagemTabela"
                @updatePagination="(param) => handleFormFiltros('paginationData', param)"
                @sortTable="(param) => handleFormFiltros('tableData', param)" />

        </main>
    </div>
</template>

<script>
import { actions, subjects } from "@/libs/acl/rules";
import { BButton } from 'bootstrap-vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import ListagemFiltros from "./parts/ListagemFiltros.vue";
import ListagemTabela from "./parts/ListagemTabela.vue";

export default {
    title: 'Unidades',

    components: {
        PageHeader,
        ListagemFiltros,
        ListagemTabela,
        BButton,
        TableFilter,
    },

    data() {
        return {
            linkItems: [
                { name: 'Unidades Operacionais', active: true }
            ],
            indiceListagemTabela: 0,
            filter: false,
            paginationData: {
                currentPage: 1,
                defaultSize: 10,
                fromLine: 0,
                toLine: 0,
                totalLines: 0,
                searchTerm: "",
                route: "unidade-list",
            },
            formData: {
                departamentoRegional: null,
                nomeUnidadeOperacional: null,
                tipo: null,
            },
            tableData: {
                columnOrder: 'descricao_unidade',
                order: 'asc'
            },
        }
    },

    methods: {
        redirectToRegisterUnidade() {
            this.$router.push({ name: 'unidade-register' });
        },
        isDifferent(a, b) {
            return Object.keys(b).some(key => b[key] !== a[key]);
        },
        handleFormFiltros(tipo, dados) {
            const temValoresDirefentes = this.isDifferent(this[tipo], dados);
            this[tipo] = { ...dados }
            if (tipo == 'formData') {
                this.paginationData.currentPage = 1
            }

            this.indiceListagemTabela = temValoresDirefentes ? this.indiceListagemTabela + 1 : this.indiceListagemTabela;
        },
    },
    computed: {
        podeCadastrarUnidade() {
            return this.$can(actions.INSERIR, subjects.UNIDADE_PROPRIA)
                || this.$can(actions.INSERIR, subjects.UNIDADE_TERCEIRA);
        },
        getParams() {
            return {
                termoPesquisa: this.formData.nomeUnidadeOperacional ?? '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.tableData.columnOrder,
                ordem: this.tableData.order,
                id_departamento: this.formData.departamentoRegional?.id_departamento ?? null,
                tipo_unidade: this.formData.tipo ?? null
            }
        },
    }

}
</script>
